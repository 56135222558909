<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <b-card-code>
      <div :hidden="hidden">
        <div class="custom-search">
          <!-- advance search input -->
          <b-row>
            <b-form-group class="col">
              <b-form-input
                placeholder="Rechercher"
                type="text"
                class="d-inline-block"
                @input="filterFunction"
              />
            </b-form-group>

            <b-form-group class="col">
              <b-form-input
                placeholder="Enregistrer du"
                type="date"
                class="d-inline-block"
                @input="startDateFilterFunction"
              />
            </b-form-group>

            <b-form-group class="col">
              <b-form-input
                @input="endDateFilterFunction"
                placeholder="Au"
                type="date"
                class="d-inline-block"
              />
            </b-form-group>

            <!-- bouton export start -->
            <b-button
              variant="outline-primary"
              class="mr-1 ml-auto"
              style="margin-bottom: 1.5%"
              size="sm"
              @click="ExportFomatExcel"
            >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              Exporter
            </b-button>

            <b-button
              variant="outline-primary"
              class="mr-1 ml-auto"
              style="margin-bottom: 1.5%"
              size="sm"
              @click="ExportAllToFomatExcel"
            >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              Tout Exporter
            </b-button>
            <!-- bouton export end -->
          </b-row>
        </div>

        <b-table
          v-model="ExcelFacturesProforma"
          responsive
          :fields="fields"
          :items="proForma"
          :filter="filter"
          :filter-function="dateFilter"
          size="lg"
        >
          <template #cell(vehicule)="data">
            <label for="">
              {{ data.item.vehicule.marque }} -
              {{ data.item.vehicule.numero_chassis }} - Année
              {{ data.item.vehicule.annee }}
            </label>
          </template>

          <template #cell(montant_vente)="data">
            {{ $thousandSeparator(data.item.montant_vente) }}
          </template>

          <template #cell(actions)="data">
            <b-button
              size="sm"
              variant="flat-primary"
              class="btn-icon"
              @click="showItem(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>
      </div>

      <div :hidden="hiddenEmpty" class="text-center text-secondary mt-2 mb-2">
        <empty-list></empty-list>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BBadge,
  BFormSelect,
  BIconCircleFill,
  BIconFileEarmarkArrowDownFill,
  BFormCheckbox,
  BRow,
  BCol,
  BContainer,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { json2excel, excel2json } from "js2excel";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    EmptyList,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BIconCircleFill,
    BIconFileEarmarkArrowDownFill,
    BRow,
    BCol,
    BBadge,
    BContainer,
    vSelect,
    BTable,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
  },
  data() {
    return {
      filter: null,
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      required,
      pageLength: 10,
      dir: false,
      selected: [true],
      selectedModule: "",
      selectedModules: [],
      addFunctionModal: false,
      isOperating: false,
      proForma: [],
      ExcelFacturesProforma: null,
      startDateFilterValue: null,
      endDateFilterValue: null,
      filterValue: null,
      fields: [
        {
          label: "Date",
          align: "left",
          sortable: false,
          key: "created_at",
        },
        { label: "Numéro", key: "pro_facture_no" },
        { label: "Client", key: "client.name" },
        { label: "Véhicule", key: "vehicule" },
        { label: "Prix Vente", key: "montant_vente" },
        { label: "Actions", align: "start", key: "actions", sortable: false },
      ],
      groupItem: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
  },
  created() {
    this.getFactures();
  },
  methods: {
    getFactures() {
      this.$http.get(`/pro-factures`).then((result) => {
        if (result.status) {
          this.hiddenLoader = true;
        }

        this.proForma = result.data.data.reverse();

        if (this.proForma.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }
      });
    },

    startDateFilterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.startDateFilterValue = val;
    },

    endDateFilterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.endDateFilterValue = val;
    },

    filterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.filterValue = val;
    },

    dateFilter(row, DateFilter) {
      var start = null;
      var end = null;
      if (this.startDateFilterValue) {
        var parts = this.startDateFilterValue.split("-");
        start = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      if (this.endDateFilterValue) {
        var parts = this.endDateFilterValue.split("-");
        end = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      var value = new Date(this.toDate(row.created_at));
      if (this.filterValue == "" || this.filterValue == null) {
        if (!start && !end) {
          return true;
        } else if (start && !end) {
          return value >= start;
        } else if (!start && end) {
          return value <= end;
        }
        return value >= start && value <= end;
      } else {
        if (
          row.client.name
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1 ||
          row.montant_vente
            .toString()
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1 ||
          row.pro_facture_no
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1 ||
          row.vehicule.marque
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1 ||
          row.vehicule.numero_chassis
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1 ||
          row.vehicule.annee
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1
        ) {
          if (!start && !end) {
            return true;
          } else if (start && !end) {
            return value >= start;
          } else if (!start && end) {
            return value <= end;
          }
          return value >= start && value <= end;
        } else {
          return false;
        }
      }
    },

    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
      // return this.reformatDate(date)
    },

    showItem(item) {
      let routeData = this.$router.resolve({
        name: "proforma-show",
        params: { id: item.id },
      });
      window.open(routeData.href, "_blank");
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.ExcelFacturesProforma.forEach((element) => {
        obj = {
          DATE: element.created_at,
          NUMERO: element.pro_facture_no,
          CLIENT: element.client.name,
          VEHICULE:
            element.vehicule.marque +
            " - " +
            element.vehicule.numero_chassis +
            " - " +
            "Année " +
            element.vehicule.annee,
          "PRIX VENTE": element.montant_vente,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste Pro forma",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.proForma.forEach((element) => {
        obj = {
          DATE: element.created_at,
          NUMERO: element.pro_facture_no,
          CLIENT: element.client.name,
          VEHICULE:
            element.vehicule.marque +
            " - " +
            element.vehicule.numero_chassis +
            " - " +
            "Année " +
            element.vehicule.annee,
          "PRIX VENTE": element.montant_vente,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste Pro forma",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.vs__selected .vs__deselect {
  fill: seashell;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}
</style>
